<template>
    <div class="py-2">
        <form @submit.prevent="submit" v-if="show">
            <fieldset :disabled="loading">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm mt-3">
                            <input
                                type="text"
                                id="lastname"
                                class="form-control"
                                name="lastname"
                                placeholder="Votre nom"
                                v-model="fields.lastname"
                            />
                            <div
                                v-if="errors && errors.lastname"
                                class="text-danger"
                            >
                                {{ errors.lastname[0] }}
                            </div>
                        </div>
                        <div class="col-sm mt-3">
                            <input
                                type="text"
                                id="firstname"
                                class="form-control"
                                name="firstname"
                                placeholder="Votre prénom"
                                v-model="fields.firstname"
                            />
                            <div
                                v-if="errors && errors.firstname"
                                class="text-danger"
                            >
                                {{ errors.firstname[0] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-sm mt-3">
                            <input
                                type="email"
                                id="email"
                                class="form-control"
                                name="email"
                                placeholder="Votre email"
                                v-model="fields.email"
                            />
                            <div
                                v-if="errors && errors.email"
                                class="text-danger"
                            >
                                {{ errors.email[0] }}
                            </div>
                        </div>
                        <div class="col-sm mt-3">
                            <select
                                class="form-control"
                                id="type"
                                name="type"
                                v-model="fields.type"
                            >
                                <option value="0" selected>
                                    -- Choisir un domaine du droit --
                                </option>
                                <option value="Droit de la famille"
                                    >Droit de la Famille et des Personnes</option
                                >
                                <option value="Droit du patrimoine"
                                    >Droit Pénal</option
                                >
                                <option value="Droit du dommage corporel"
                                    >Droit du Dommage Corporel</option
                                >
                                <option value="Autre demande"
                                    >Autre demande</option
                                >
                            </select>
                            <div
                                v-if="errors && errors.type"
                                class="text-danger"
                            >
                                {{ errors.type[0] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-3">
                    <textarea
                        name="message"
                        id="message"
                        class="form-control"
                        rows="5"
                        placeholder="Votre message..."
                        v-model="fields.message"
                    ></textarea>
                    <div v-if="errors && errors.message" class="text-danger">
                        {{ errors.message[0] }}
                    </div>
                </div>

                <div class="text-center mt-3">
                    <button type="submit" class="btn m-2 px-4">Envoyer</button>

                    <button
                        type="button"
                        @click="deleteForm"
                        class="btn m-2 px-4"
                    >
                        Rénitialiser
                    </button>
                </div>
            </fieldset>
        </form>

        <div v-else class="sendMessage">
            <div class="text-center py-3" role="alert">
                <p>Merci pour votre message</p>
                <p>
                    Maître Andolfatto vous répondra dans les plus bref délais.
                </p>
                <a href="/accueil">RETOURNER À L'ACCUEIL</a>
            </div>
        </div>

        <div id="axiosForm" class="verifLoading mt-4" v-if="loading">
            <div class="justify-content-center d-flex">
                <div class="spinner-border" role="status">
                    <span class="sr-only"
                        >Verfification de la demande en cours</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  data() {
    return {
      fields: {
        type: "0",
      },
      errors: {},
      success: false,
      loading: false,
      show: true,
    };
  },
  methods: {
  
    submit() {
        this.loading = true,
          axios
            .post("/contact", this.fields)
            .then(_response => {
              this.fields = {}; //Clear input fields.
              this.success = true;
              this.show = false;
            })
            .catch(error => {
                console.log(error.response)
              if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
              }
            })
            .finally(() => {
              this.loading = false;
            });
    },
    deleteForm() {
      this.fields.lastname = "";
      this.fields.firstname = "";
      this.fields.email = "";
      this.fields.message = "";
      this.fields.type = "0"
      this.errors = "";
    },
  },
};
</script>